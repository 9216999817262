import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";
import PrivateRoute from "./components/PrivateRoute";
import Navbar from "./components/Navbar";

import LoginPage from "./components/LoginPage";
import OptionsPage from "./components/OptionsPage";
import Soporte from "./components/Soporte";
import Repsop from "./components/report_sop";
import ThreeButtonsPage from "./components/ThreeButtonsPage";
import Panel from "./components/Panel";
import UserManagementPage from "./components/UserManagementPage";
import Reporte from "./components/Report";
import Consul from "./components/Consult";

const App = () => {
  const location = useLocation();

  // Oculta el Navbar si estamos en las rutas de login ("/" o "/login")
  const hideNavbarPaths = ["/", "/login"];
  const showNavbar = !hideNavbarPaths.includes(location.pathname);

  return (
    <div>
      {showNavbar && <Navbar />}
      <Routes>
        {/* Rutas públicas */}
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />

        {/* Rutas protegidas */}
        <Route path="/reportsop" element={<PrivateRoute><Repsop /></PrivateRoute>} />
        <Route path="/OptionsPage" element={<OptionsPage />} />
        <Route path="/soporte" element={<PrivateRoute><Soporte /></PrivateRoute>} />
        <Route path="/consulta" element={<PrivateRoute><ThreeButtonsPage /></PrivateRoute>} />
        <Route path="/panel" element={<PrivateRoute><Panel /></PrivateRoute>} />
        <Route path="/userm" element={<PrivateRoute><UserManagementPage /></PrivateRoute>} />
        <Route path="/Report" element={<PrivateRoute><Reporte /></PrivateRoute>} />
        <Route path="/Consult" element={<PrivateRoute><Consul /></PrivateRoute>} />
      </Routes>
    </div>
  );
};

export default function AppWrapper() {
  return (
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  );
}

