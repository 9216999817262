import React from 'react';
import { Link } from 'react-router-dom';
import './ThreeButtonsPage.css';

const ThreeButtonsPage = () => {
  const buttons = [
    { id: 1, name: 'Tiempo real', description: 'Estaciones en tiempo real', image: '/icon/time.png', link: '/panel' },
    { id: 4, name: 'Ver Reportes', description: 'Visualizar reportes activos', image: '/icon/consult_l.png', link: '/reportsop' },
    { id: 5, name: 'Paro', description: 'levantar un reporte', image: '/icon/consult_l.png', link: '/OptionsPage' },
  ];

  const handleButtonClick = (button) => {
    console.log(`Botón clickeado: ${button.name}`);
  };

  return (
    <div className="three-buttons-container">
      <div className="buttons-grid">
        {buttons.map(button => (
          button.link ? (
            <Link to={button.link} key={button.id} className="button-card">
              <img src={button.image} alt={button.name} className="button-image" />
              <h3>{button.name}</h3>
              <p>{button.description}</p>
            </Link>
          ) : (
            <div key={button.id} className="button-card" onClick={() => handleButtonClick(button)}>
              <img src={button.image} alt={button.name} className="button-image" />
              <h3>{button.name}</h3>
              <p>{button.description}</p>
            </div>
          )
        ))}
      </div>
    </div>
  );
};

export default ThreeButtonsPage;
