import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom"; // Para la redirección
import axios from "axios";
import styles from "./OptionsPage.module.css"; // Importamos el archivo CSS modular
import { API_URL } from "./config";

const OptionsPage = () => {
  const [areas, setAreas] = useState([]);
  const [selectedArea, setSelectedArea] = useState(null);
  const [paroData, setParoData] = useState([]);
  const [selectedParo, setSelectedParo] = useState(null);
  const [showFormModal, setShowFormModal] = useState(false);
  const [descripcion, setDescripcion] = useState("");
  const [userId, setUserId] = useState(null);
  const [userName, setUserName] = useState("");
  const [loading, setLoading] = useState(true);
  const [showAnimation, setShowAnimation] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Simula una carga de 5 segundos antes de mostrar la página
    const timeout = setTimeout(() => setLoading(false), 5000);

    const usuarioLogueado = localStorage.getItem("id_usuario");
    const nombreUsuario = localStorage.getItem("nombre_usuario");

    if (usuarioLogueado) setUserId(parseInt(usuarioLogueado, 10));
    if (nombreUsuario) setUserName(nombreUsuario);

    axios
      .get(`${API_URL}/areas`)
      .then((response) => setAreas(response.data))
      .catch((error) => console.error("Error fetching areas:", error));

    return () => clearTimeout(timeout);
  }, []);

  const handleAreaClick = (area) => {
    setSelectedArea(area);
    axios
      .get(`${API_URL}/paro?area=${area.id_area}`)
      .then((response) => setParoData(response.data))
      .catch((error) => console.error("Error fetching paro data:", error));
  };

  const handleParoClick = (paro) => {
    setSelectedParo(paro);
    setShowFormModal(true);
  };

  const handleCloseFormModal = () => {
    setShowFormModal(false);
    setSelectedParo(null);
    setDescripcion("");
  };

  const handleSendReport = () => {
    if (!userId || !descripcion || !selectedParo || !selectedArea) {
      alert("⚠️ Todos los campos son obligatorios.");
      return;
    }
  
    const now = new Date();
    const fechaActual = now.toISOString().split("T")[0]; // 🟢 YYYY-MM-DD (SOLO FECHA)
    const horaActual = now.toTimeString().split(" ")[0]; // 🟢 HH:MM:SS (SOLO HORA)
  
    const fallaData = {
      descripcion,
      id_paro: selectedParo.id_paro,
      id_urgencia: 2,
      id_usuario: userId,
      id_area: selectedArea.id_area,
      fecha_inicio: fechaActual, // ✅ Solo guardamos la fecha
      hora_inicio: horaActual, // ✅ Solo guardamos la hora
      id_status: 5,
    };
  
    axios
      .post(`${API_URL}/falla`, fallaData)
      .then(() => {
        console.log("✅ Paro registrado correctamente");
  
        // 🔴 FORZAR ACTUALIZACIÓN DE `Panel.js`
        setTimeout(() => {
          window.dispatchEvent(new Event("actualizarFallas"));
        }, 2000);
  
        setShowAnimation(true);
        handleCloseFormModal();
        setTimeout(() => {
          localStorage.removeItem("id_usuario");
          localStorage.removeItem("nombre_usuario");
          navigate("/login");
        }, 3000);
      })
      .catch((error) => {
        console.error("❌ Error al enviar el reporte:", error);
        alert("❌ Hubo un error al enviar el reporte. Inténtalo de nuevo.");
      });
  };
  
  


  const getImageUrl = (ruta) => {
    return ruta ? `${API_URL}${ruta}` : "https://placehold.co/96";
  };

  // Mapeo de id_zona a nombre de zona
  const zoneMapping = {
    1: "Workzone 1",
    2: "Workzone 2",
    3: "Workzone 3",
    4: "Workzone 4",
  };

  // Agrupamos las áreas por zona
  const groupedAreas = areas.reduce((groups, area) => {
    const zoneName = zoneMapping[area.id_zona] || "Zona desconocida";
    if (!groups[zoneName]) groups[zoneName] = [];
    groups[zoneName].push(area);
    return groups;
  }, {});

  if (loading) {
    return <div className={styles["loading-screen"]}>Cargando...</div>;
  }

  return (
    <div className={styles.container}>
     

      {/* Renderizado de cada grupo de áreas según la zona */}
      {Object.keys(groupedAreas).map((zoneName) => (
        <div key={zoneName} className={styles["zone-group"]}>
          <h3>{zoneName}</h3>
          <div className={styles["area-buttons-container"]}>
            {groupedAreas[zoneName].map((area) => (
              <button
                key={area.id_area}
                className={styles["area-button"]}
                onClick={() => handleAreaClick(area)}
              >
                <img
                  src={getImageUrl(area.imagen_area)}
                  alt={area.nombre_area}
                  className={styles["area-image"]}
                />
                <span className={styles["area-name"]}>
                  {area.nombre_area}
                </span>
              </button>
            ))}
          </div>
        </div>
      ))}

      {/* Modal de selección de paro */}
      {selectedArea && (
        <div
          className={styles.modal}
          onClick={(e) =>
            e.target === e.currentTarget && setSelectedArea(null)
          }
        >
          <div
            className={styles["modal-content"]}
            onClick={(e) => e.stopPropagation()}
          >
            <span
              className={styles["close-button"]}
              onClick={() => setSelectedArea(null)}
            >
              &times;
            </span>
            <h2>{selectedArea.nombre_area}</h2>
            <div className={styles["modal-body"]}>
              <img
                src={getImageUrl(selectedArea.imagen_area)}
                alt={selectedArea.nombre_area}
                className={styles["modal-image"]}
              />
              <div className={styles["paro-buttons-container"]}>
                {paroData.map((paro) => (
                  <button
                    key={paro.id_paro}
                    className={styles["paro-button"]}
                    data-paro={paro.descripcion.trim()}
                    onClick={() => handleParoClick(paro)}
                  >
                    {paro.descripcion}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Modal de formulario para enviar reporte */}
      {showFormModal && (
        <div className={styles["form-modal"]}>
          <div className={styles["form-modal-content"]}>
            <h3>Paro seleccionado: {selectedParo.descripcion}</h3>
            <p>Área seleccionada: {selectedArea.nombre_area}</p>
            <p>Usuario logueado: {userName}</p>
            <textarea
              placeholder="Escribe tu comentario..."
              value={descripcion}
              onChange={(e) => setDescripcion(e.target.value)}
            />
            <button onClick={handleSendReport}>Enviar</button>
            <button onClick={handleCloseFormModal}>Salir</button>
          </div>
        </div>
      )}

      {/* Animación de registro de falla */}
      {showAnimation && (
        <div className={styles["animation-overlay"]}>
          <div className={styles["animation-content"]}>
            <p>Registrando falla...</p>
            <div className={styles.spinner}></div>
          </div>
        </div>
      )}
    </div>
  );
};

export default OptionsPage;
