import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from "../context/AuthContext"; 
import styles from './report_sop.module.css';
import { API_URL } from "./config";

const UserManagementPage = () => {
  const [fallas, setFallas] = useState([]);
  const [areas, setAreas] = useState([]);
  const [error, setError] = useState(null);
  const [editingFalla, setEditingFalla] = useState(null);
  const [paros, setParos] = useState([]);
  const [urgencias, setUrgencias] = useState([]);
  const [activarFalla, setActivarFalla] = useState(null);
  const [notification, setNotification] = useState(null);

  const navigate = useNavigate();
  const { idUsuario } = useContext(AuthContext); 
  const nombreUsuario = "Usuario Ejemplo"; // Ajusta según tu contexto

  // Mostrar notificación flotante
  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  // Obtener y filtrar fallas con status "Paro"
  const fetchFallas = () => {
    axios
      .get(`${API_URL}/falla`)
      .then((response) => {
        const fallasParo = response.data.filter(
          (falla) => falla.status_nombre === 'Paro'
        );
        setFallas(fallasParo);
      })
      .catch(() => setError('Hubo un problema al cargar las fallas.'));
  };

  // Montar: obtener fallas, áreas, paros, urgencias
  useEffect(() => {
    fetchFallas();

    axios
      .get(`${API_URL}/areas`)
      .then((response) => setAreas(response.data))
      .catch(() => setError('Hubo un problema al cargar las áreas.'));

    axios
      .all([axios.get(`${API_URL}/paro`), axios.get(`${API_URL}/urgencia`)])
      .then(
        axios.spread((paroRes, urgenciaRes) => {
          setParos(paroRes.data);
          setUrgencias(urgenciaRes.data);
        })
      )
      .catch(() => setError('Hubo un problema al cargar las opciones.'));
  }, []);

  // Polling cada 5s
  useEffect(() => {
    const interval = setInterval(() => {
      fetchFallas();
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  // Función para calcular el turno basado en la hora de inicio (formato "HH:mm:ss" o "HH:mm")
  const calculateTurno = (hora) => {
    // Separamos la hora, minutos (y segundos si existen)
    const partes = hora.split(":").map(Number);
    if (partes.length < 2) return '';
    const [hours, minutes] = partes;
    const totalMinutes = hours * 60 + minutes;

    // Turno 1: 07:00 (420 min) a 16:40 (1000 min)
    if (totalMinutes >= 420 && totalMinutes <= 1000) {
      return '1';
    }
    // Turno 2: 16:41 (1001 min) a 23:59 (1439 min) o 00:00 (0 min) a 01:40 (100 min)
    else if ((totalMinutes >= 1001 && totalMinutes <= 1439) || (totalMinutes >= 0 && totalMinutes <= 100)) {
      return '2';
    }
    // Tiempo extra: 01:41 (101 min) a 06:59 (419 min)
    else if (totalMinutes >= 101 && totalMinutes <= 419) {
      return 'Tiempo extra';
    }
    return '';
  };

  // Editar falla (abre modal)
  const handleEditFalla = (falla) => {
    setEditingFalla({
      ...falla,
      descripcion: falla.falla_descripcion
    });
  };

  // Guardar cambios de la falla (PUT)
  const handleSaveFalla = () => {
    axios
      .put(`${API_URL}/falla/${editingFalla.id_falla}`, editingFalla)
      .then(() => {
        fetchFallas();
        setEditingFalla(null);
        showNotification("Falla actualizada exitosamente.", "success");
      })
      .catch(() => {
        setError('Error al actualizar la falla.');
        showNotification("Error al actualizar la falla.", "error");
      });
  };

  // Abrir modal de activar falla
  const handleOpenActivarFalla = (falla) => {
    setActivarFalla({ ...falla, comentario: '' });
  };

  // Registrar reporte y actualizar la lista de fallas
  const handleRegistrarReporte = () => {
    if (!activarFalla) return;

    // Obtenemos la hora actual sin modificar (la lógica de ajustar la fecha se hará en el servidor)
    const currentTime = new Date();
    const pad = (num) => num.toString().padStart(2, '0');
    const fecha = `${currentTime.getFullYear()}-${pad(currentTime.getMonth() + 1)}-${pad(currentTime.getDate())}`;
    const timePart = currentTime.toTimeString().split(" ")[0];
    const horaFinString = `${fecha} ${timePart}`;

    // Cálculo del tiempo de paro usando la hora_inicio de la falla
    const [startH, startM, startS] = activarFalla.hora_inicio.split(":").map(Number);
    const startTime = new Date();
    startTime.setHours(startH, startM, startS, 0);

    // Calculamos la diferencia absoluta en segundos y la formateamos
    let diffMs = startTime - currentTime;
    const diffSec = Math.floor(Math.abs(diffMs) / 1000);
    const hours = Math.floor(diffSec / 3600).toString().padStart(2, "0");
    const minutes = Math.floor((diffSec % 3600) / 60).toString().padStart(2, "0");
    const seconds = (diffSec % 60).toString().padStart(2, "0");
    const tiempoParo = `${hours}:${minutes}:${seconds}`;

    if (!idUsuario) {
      setError("No se encontró información del usuario logueado.");
      return;
    }

    const areaEncontrada = areas.find(
      (area) => area.id_area === activarFalla.id_area
    );
    const idZona = areaEncontrada ? areaEncontrada.id_zona : null;
    if (!idZona) {
      setError("No se encontró la zona correspondiente al área de la falla.");
      return;
    }

    // Calculamos el turno a partir de la hora_inicio de la falla
    const turnoCalculado = calculateTurno(activarFalla.hora_inicio);

    const nuevoReporte = {
      id_usuario: activarFalla.id_usuario,
      id_usuario_solucionador: idUsuario,
      id_area: activarFalla.id_area,
      id_zona: idZona,
      id_paro: activarFalla.id_paro,
      id_falla: activarFalla.id_falla,
      hora_inicio: activarFalla.hora_inicio,
      hora_fin: horaFinString,
      tiempo_paro: tiempoParo,
      accion_correctiva: activarFalla.comentario || "",
      descripcion: activarFalla.falla_descripcion,
      id_turno: turnoCalculado  // Se asigna el turno calculado
    };

    axios
      .post(`${API_URL}/reporte`, nuevoReporte)
      .then((response) => {
        console.log("Reporte registrado:", response.data);
        axios
          .put(`${API_URL}/falla/${activarFalla.id_falla}/status`, {
            id_status: 4
          })
          .then((updateResponse) => {
            console.log("Status de la falla actualizado:", updateResponse.data);
            fetchFallas();
            showNotification("Reporte registrado exitosamente.", "success");
            setActivarFalla(null);
          })
          .catch((err) => {
            console.error("Error al actualizar status de la falla:", err);
            showNotification(
              "Reporte registrado, pero error al actualizar el status.",
              "error"
            );
            setActivarFalla(null);
          });
      })
      .catch((err) => {
        console.error("Error al registrar el reporte:", err);
        showNotification("Error al registrar el reporte.", "error");
      });
  };

  return (
    <div className={styles["page-container"]}>
      {/* Encabezado */}
      <header className={styles["page-header"]}>
        <div className={styles["header-left"]}></div>
        <div className={styles["header-center"]}>
          {/* Ícono y título */}
          <img src="/icon/consult_l.png" alt="Icono" className={styles["header-icon"]} />
          <h1>Ver Fallas</h1>
        </div>
      </header>

      {/* Notificación flotante */}
      {notification && (
        <div
          className={`${styles["floating-notification"]} ${
            notification.type === "success" ? styles.success : styles.error
          }`}
        >
          {notification.message}
        </div>
      )}

      {/* Mensaje de error global */}
      {error && (
        <div className={styles["error-message"]}>
          <p>{error}</p>
        </div>
      )}

      {/* Tabla de fallas */}
      <div className={styles["table-container"]}>
        <table className={styles["report-table"]}>
          <thead>
            <tr>
              <th>ID Falla</th>
              <th>Descripción</th>
              <th>Paro</th>
              <th>Urgencia</th>
              <th>Usuario</th>
              <th>Área</th>
              <th>Hora Inicio</th>
              <th>Status</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {fallas.map((falla) => (
              <tr key={`falla-${falla.id_falla}`}>
                <td>{falla.id_falla}</td>
                <td>{falla.falla_descripcion}</td>
                <td>{falla.paro_descripcion}</td>
                <td>{falla.nombre_urgencia}</td>
                <td>{falla.usuario_nombre}</td>
                <td>{falla.nombre_area}</td>
                <td>{falla.hora_inicio}</td>
                <td>{falla.status_nombre}</td>
                <td>
                  <button onClick={() => handleEditFalla(falla)}>Editar</button>
                  {falla.status_nombre === 'Paro' && (
                    <button onClick={() => handleOpenActivarFalla(falla)}>
                      Activar
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal de Editar Falla */}
      {editingFalla && (
        <div className={styles["modal-overlay"]}>
          <div className={styles.modal}>
            <div className={styles["modal-content"]}>
              <h3>Editar Falla</h3>
              <label>ID Falla: {editingFalla.id_falla}</label>
              <input
                type="text"
                name="descripcion"
                value={editingFalla.descripcion}
                disabled
              />
              <select
                name="id_paro"
                value={editingFalla.id_paro}
                onChange={(e) =>
                  setEditingFalla({
                    ...editingFalla,
                    id_paro: e.target.value,
                  })
                }
              >
                <option value="">Seleccionar Paro</option>
                {paros.map((paro) => (
                  <option key={paro.id_paro} value={paro.id_paro}>
                    {paro.descripcion}
                  </option>
                ))}
              </select>
              <select
                name="id_urgencia"
                value={editingFalla.id_urgencia}
                onChange={(e) =>
                  setEditingFalla({
                    ...editingFalla,
                    id_urgencia: e.target.value,
                  })
                }
              >
                <option value="">Seleccionar Urgencia</option>
                {urgencias.map((urgencia) => (
                  <option key={urgencia.id_urgencia} value={urgencia.id_urgencia}>
                    {urgencia.nombre_urgencia}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="usuario_nombre"
                value={editingFalla.usuario_nombre}
                disabled
              />
              <input
                type="text"
                name="nombre_area"
                value={editingFalla.nombre_area}
                disabled
              />
              <input
                type="time"
                name="hora_inicio"
                value={editingFalla.hora_inicio}
                disabled
              />
              <input
                type="text"
                name="status_nombre"
                value={editingFalla.status_nombre}
                disabled
              />
              <button onClick={handleSaveFalla}>Guardar Cambios</button>
              <button
                className={styles["cancel"]}
                onClick={() => setEditingFalla(null)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Modal de Activar Falla */}
      {activarFalla && (
        <div className={styles["modal-overlay"]}>
          <div className={styles.modal}>
            <div className={styles["modal-content"]}>
              <h3>Activar Falla</h3>
              <p>
                <strong>ID Falla:</strong> {activarFalla.id_falla}
              </p>
              <p>
                <strong>Descripción:</strong> {activarFalla.falla_descripcion}
              </p>
              <p>
                <strong>Área:</strong> {activarFalla.nombre_area}
              </p>
              <p>
                <strong>Paro:</strong> {activarFalla.paro_descripcion}
              </p>
              <p>
                <strong>Urgencia:</strong> {activarFalla.nombre_urgencia}
              </p>
              <p>
                <strong>Usuario:</strong> {activarFalla.usuario_nombre}
              </p>
              <p>
                <strong>Hora Inicio:</strong> {activarFalla.hora_inicio}
              </p>
              <label>
                <strong>Comentario:</strong>
                <input
                  type="text"
                  value={activarFalla.comentario || ""}
                  onChange={(e) =>
                    setActivarFalla({
                      ...activarFalla,
                      comentario: e.target.value,
                    })
                  }
                />
              </label>
              <button onClick={handleRegistrarReporte}>Activar</button>
              <button
                className={styles["cancel"]}
                onClick={() => setActivarFalla(null)}
              >
                Cancelar
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagementPage;
