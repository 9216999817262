import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import './ThreeButtonsPage.css';

const buttons = [
  { id: 1, name: 'Tiempo real', description: 'Estaciones en tiempo real', image: '/icon/time.png', link: '/panel' },
  { id: 3, name: 'Consulta', description: 'Consultar información', image: '/icon/consult.png', link: '/consult' },
  { id: 4, name: 'Editar', description: 'Editar reportes', image: '/icon/edit.png', link: '/reportsop' },
  { id: 5, name: 'Usuarisos', description: 'Administrar usurarios', image: '/icon/people.png', link: '/userm' },
];

const ThreeButtonsPage = () => {
  const [showModal, setShowModal] = useState(false);

  const handleButtonClick = (button) => {
    if (button.id === 2) {
      setShowModal(true);
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="three-buttons-container">
      <div className="buttons-grid">
        {buttons.map(button => (
          button.link ? (
            <Link to={button.link} key={button.id} className="button-card">
              <img src={button.image} alt={button.name} className="button-image" />
              <h3>{button.name}</h3>
              <p>{button.description}</p>
            </Link>
          ) : (
            <div key={button.id} className="button-card" onClick={() => handleButtonClick(button)}>
              <img src={button.image} alt={button.name} className="button-image" />
              <h3>{button.name}</h3>
              <p>{button.description}</p>
            </div>
          )
        ))}
      </div>
      {showModal && (
        <div className="overlay">
          <div className="modal">
            <button className="close-button" onClick={closeModal}>Cerrar</button>
            <div className="modal-content">
              <div className="modal-left">
                <h2>Selecciona una fecha</h2>
                <input type="date" className="modal-calendar" />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ThreeButtonsPage;
