import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './UserManagementPage.css';
import { API_URL } from "./config";

const UserManagementPage = () => {
  const [users, setUsers] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [error, setError] = useState(null);
  const [newUser, setNewUser] = useState({ nombre: '', contraseña: '', id_cargo: '', numero_usuario: '' });
  const [editingUser, setEditingUser] = useState(null);
  const [formVisible, setFormVisible] = useState(false);
  const [notification, setNotification] = useState(null);

  // Estados para el filtrado
  const [filterNombre, setFilterNombre] = useState("");
  const [filterId, setFilterId] = useState("");
  const [filterNumero, setFilterNumero] = useState("");
  // Filtros aplicados (se usan para renderizar la tabla)
  const [appliedFilters, setAppliedFilters] = useState({ nombre: "", id: "", numero: "" });

  const navigate = useNavigate();

  // Función para mostrar notificación flotante
  const showNotification = (message, type) => {
    setNotification({ message, type });
    setTimeout(() => {
      setNotification(null);
    }, 3000);
  };

  // Función para obtener la lista de usuarios desde la API
  const fetchUsers = () => {
    axios.get(`${API_URL}/usuarios`)
      .then(response => setUsers(response.data))
      .catch(() => setError('Hubo un problema al cargar los usuarios.'));
  };

  // Al montar el componente, se obtienen los usuarios y cargos
  useEffect(() => {
    fetchUsers();

    axios.get(`${API_URL}/cargo`)
      .then(response => setCargos(response.data))
      .catch(() => setError('Hubo un problema al cargar los cargos.'));
  }, []);

  const handleInputChange = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value });
  };

  const handleEditChange = (e) => {
    setEditingUser({ ...editingUser, [e.target.name]: e.target.value });
  };

  const handleAddUser = () => {
    axios.post(`${API_URL}/usuarios`, newUser)
      .then(response => {
        // Se re-obtienen los usuarios para actualizar la tabla
        fetchUsers();
        setNewUser({ nombre: '', contraseña: '', id_cargo: '', numero_usuario: '' });
        showNotification("Usuario agregado exitosamente.", "success");
      })
      .catch(() => setError('Error al agregar usuario.'));
  };

  const handleEditUser = () => {
    axios.put(`${API_URL}/usuarios/${editingUser.id_usuario}`, editingUser)
      .then(() => {
        // Actualizamos la tabla volviendo a obtener los usuarios
        fetchUsers();
        setEditingUser(null);
        showNotification("Usuario actualizado exitosamente.", "success");
      })
      .catch(() => {
        setError('Error al actualizar usuario.');
        showNotification("Error al actualizar usuario.", "error");
      });
  };

  // Se calcula el arreglo de usuarios que se va a renderizar según los filtros aplicados
  const filteredUsers = users.filter(user => {
    const matchesNombre = appliedFilters.nombre === "" || user.nombre.toLowerCase().includes(appliedFilters.nombre.toLowerCase());
    const matchesId = appliedFilters.id === "" || user.id_usuario.toString() === appliedFilters.id;
    const matchesNumero = appliedFilters.numero === "" || (user.numero_usuario && user.numero_usuario.toLowerCase().includes(appliedFilters.numero.toLowerCase()));
    return matchesNombre && matchesId && matchesNumero;
  });

  return (
    <div className="user-management-container">
      <header className="user-management-header">
        <img src="/icon/people.png" alt="Logo" className="header-image" />
        <h1>Gestión de Usuarios</h1>
      </header>

      {notification && (
        <div className={`floating-notification ${notification.type}`}>
          {notification.message}
        </div>
      )}

      {error && <div className="error-message"><p>{error}</p></div>}

      <button className="toggle-form-button" onClick={() => setFormVisible(!formVisible)}>
        {formVisible ? 'Ocultar Formulario' : 'Agregar Usuario'}
      </button>

      {formVisible && (
        <div className="add-user-form">
          <input
            type="text"
            name="nombre"
            placeholder="Nombre"
            value={newUser.nombre}
            onChange={handleInputChange}
          />
          <input
            type="password"
            name="contraseña"
            placeholder="Contraseña"
            value={newUser.contraseña}
            onChange={handleInputChange}
          />
          <select name="id_cargo" value={newUser.id_cargo} onChange={handleInputChange}>
            <option value="">Seleccione un cargo</option>
            {cargos.map(cargo => (
              <option key={cargo.id_cargo} value={cargo.id_cargo}>
                {cargo.nombre_cargo}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="numero_usuario"
            placeholder="Número de Usuario"
            value={newUser.numero_usuario}
            onChange={handleInputChange}
          />
          <button onClick={handleAddUser}>Agregar Usuario</button>
        </div>
      )}

      {/* Sección de filtrado */}
      <div className="filter-section">
        <input
          type="text"
          placeholder="Filtrar por Nombre"
          value={filterNombre}
          onChange={e => setFilterNombre(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtrar por ID"
          value={filterId}
          onChange={e => setFilterId(e.target.value)}
        />
        <input
          type="text"
          placeholder="Filtrar por Número de Usuario"
          value={filterNumero}
          onChange={e => setFilterNumero(e.target.value)}
        />
        <button onClick={() => setAppliedFilters({ nombre: filterNombre, id: filterId, numero: filterNumero })}>
          Filtrar
        </button>
        <button onClick={() => {
          setFilterNombre("");
          setFilterId("");
          setFilterNumero("");
          setAppliedFilters({ nombre: "", id: "", numero: "" });
        }}>
          Mostrar Todos
        </button>
      </div>

      <table className="user-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Nombre</th>
            <th>Contraseña</th>
            <th>Cargo</th>
            <th>Número de Usuario</th>
            <th>Acciones</th>
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map(user => (
            <tr key={user.id_usuario}>
              <td>{user.id_usuario}</td>
              <td>{user.nombre}</td>
              <td>{user.contraseña}</td>
              <td>{user.cargo_nombre}</td>
              <td>{user.numero_usuario}</td>
              <td>
                <button onClick={() => setEditingUser(user)}>Editar</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {editingUser && (
        <div className="modal-overlay">
          <div className="modal-content user-management-container">
            <h3>Editar Usuario</h3>
            <input
              type="text"
              name="nombre"
              value={editingUser.nombre}
              onChange={handleEditChange}
            />
            <input
              type="password"
              name="contraseña"
              value={editingUser.contraseña}
              onChange={handleEditChange}
            />
            <select name="id_cargo" value={editingUser.id_cargo} onChange={handleEditChange}>
              {cargos.map(cargo => (
                <option key={cargo.id_cargo} value={cargo.id_cargo}>
                  {cargo.nombre_cargo}
                </option>
              ))}
            </select>
            <input
              type="text"
              name="numero_usuario"
              value={editingUser.numero_usuario}
              onChange={handleEditChange}
            />
            <button onClick={handleEditUser}>Guardar Cambios</button>
            <button onClick={() => setEditingUser(null)}>Cancelar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserManagementPage;
